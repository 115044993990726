import { ReactNode, useContext } from 'react'

import { AuthContext } from 'contexts/AuthContext'
import UnloggedHeader from 'components/layout/unlogged-header'
import Icon from 'components/common/icon'
import Link from 'next/link'

import style from './lost.module.scss'

export default function Layout({ children }: { children: ReactNode }) {
  const { user } = useContext(AuthContext)

  return (
    <>
      <div className={style['lost-page']}>
        <div className={style['message-area']}>
          <UnloggedHeader />
          <div className={style['main-content']}>
            {children}
          </div>

        </div>
        <div className={style['links-area']}>
          <div className={style['menu-list']}>
            <div>
              <Link href="https://pureskills.com" passHref>
                <a><Icon name="Home" style={{ marginRight: '8px' }} /> Pagina Inicial</a>
              </Link>
            </div>
            {user ? <>
              {user?.profile?.kind !== 'student' &&
                <div>
                  <Link href="/dashboard" passHref>
                    <a><Icon name="BarChart3" style={{ marginRight: '8px' }} /> Painel</a>
                  </Link>
                </div>
              }

              <div>
                <Link href="/memberarea/customer" passHref>
                  <a><Icon name="ShoppingBag" style={{ marginRight: '8px' }} /> Minhas Compras</a>
                </Link>
              </div>

              <div>
                <Link href="/settings" passHref>
                  <a><Icon name="Settings" style={{ marginRight: '8px' }} /> Configurações</a>
                </Link>
              </div>
            </>
            :
            <div>
              <Link href="/login" passHref>
                <a><Icon name="LogIn" style={{ marginRight: '8px' }} /> Entrar</a>
              </Link>
            </div>
            }
            <div>
              <Link href="https://ajuda.pureskills.com" passHref>
                <a><Icon name="HelpCircle" style={{ marginRight: '8px' }} /> Ajuda</a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
